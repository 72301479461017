module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-gtag@5.13.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@8.57.0__eslint-impo_pftkwyr5h3ex5jrw3qbljcmgpi/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-LQN806EWJK"],"pluginConfig":{"head":false,"respectDNT":true,"delayOnRouteUpdate":1000,"exclude":[],"origin":"https://www.googletagmanager.com"},"gtagConfig":{}},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@8.57.0__eslint-import-_vwa5royogoowyr5ypbllisb4iu/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"yourssu","short_name":"yourssu","start_url":"/","icon":"src/assets/logo/logo.svg","cache_busting_mode":"query","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"bf5b81550f857491b012d0968c8d0000"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-breakpoints@1.3.11_gatsby@5.13.7_babel-eslint@10.1.0_eslint@8.57.0__eslint-impo_6ck3guz5s4zfrm5dompf6wnvq4/node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"xs":"(max-width: 390px)","query550":"(max-width: 550px)","query669":"(max-width: 669px)","sm":"(max-width: 720px)","md":"(max-width: 1080px)","lg":"(max-width: 1440px)","xl":"(max-width: 1920px)"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.7_babel-eslint@10.1.0_eslint@8.57.0__eslint-import-resolver-typescript@3.6.3_@typ_wzogwsuwxigmwagjlalqjmv36m/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
